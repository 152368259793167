import React from "react"

export default class extends React.Component {
  defaultConfig = {
    items: 1,
    loop: !1,
    nav: !1,
    navText: [],
    dots: !0,
    slideBy: 1,
    lazyLoad: !1,
    autoplay: !1,
    autoplayTimeout: 4e3,
    responsive: {},
    animateOut: !1,
    animateIn: !1,
    smartSpeed: 450,
    navSpeed: 450,
  }

  componentDidMount = () => {
    const config = window.$.extend(this.defaultConfig, this.props.config)
    this.$el = window.$(this.el)
    setTimeout(() => {
      this.$el.owlCarousel(config)
    }, process.env.CAROUSEL_DELAY)
  }

  componentWillUnmount = () => {
    this.$el.owlCarousel("destroy")
  }

  render = () => {
    const { config, ...props } = this.props
    return (
      <div {...props} ref={el => (this.el = el)}>
        {this.props.children}
      </div>
    )
  }
}
